@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;


}
 

body {

  font-family: 'Poppins', sans-serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scroll-behavior: smooth;
  scrollbar-width: none;

  
}

@tailwind base;
@tailwind components;
@tailwind utilities;


/* add scroll width, behaviors */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* bahavior */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* handle */
::-webkit-scrollbar-thumb {
  background: #e7e6e6;
}

/* handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* scroll radius */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* scroll bar heigth */
::-webkit-scrollbar {
 line-height: 10px;}

